import { baseTheme } from '@ljagis/react-mui-themes';
import { createMuiTheme } from '@material-ui/core';

const theme = createMuiTheme(baseTheme, {
  palette: {
    primary: {
      main: '#4c9e5d',
      light: '#add7b3',
      dark: '#00742a',
      contrastText: '#ffffff'
    },
    secondary: {
      main: '#3A495F',
      light: '#7c91ac',
      dark: '#243a51',
      contrastText: '#ffffff'
    }
  }
});

export default theme;
