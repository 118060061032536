import { MapProvider, MapboxProvider } from '@ljagis/react-mapping';
import React from 'react';

import Layout from '../components/Layout';

const { REACT_APP_MAPBOX_ACCESS_TOKEN, PUBLIC_URL } = process.env;

const mapStyles = [
  {
    title: 'Streets',
    previewImage: `${PUBLIC_URL}/mapstyles/streets.png`,
    previewImage2x: `${PUBLIC_URL}/mapstyles/streets@2x.png`,
    styleUrl: `${PUBLIC_URL}/mapstyles/streets.json`
  },
  {
    title: 'Aerial',
    previewImage: `${PUBLIC_URL}/mapstyles/aerial.png`,
    previewImage2x: `${PUBLIC_URL}/mapstyles/aerial@2x.png`,
    styleUrl: `${PUBLIC_URL}/mapstyles/aerial.json`
  },
  {
    title: 'Full Aerial',
    previewImage: `${PUBLIC_URL}/mapstyles/mapbox.png`,
    previewImage2x: `${PUBLIC_URL}/mapstyles/mapbox@2x.png`,
    styleUrl: `${PUBLIC_URL}/mapstyles/mapbox.json`
  }
];

const DistrictMap: React.FC = () => (
  <MapboxProvider accessToken={REACT_APP_MAPBOX_ACCESS_TOKEN || ''}>
    <MapProvider>
      <Layout
        logo={`${PUBLIC_URL}/logo.png`}
        title="TAG Motion Map 2.0 (Beta)"
        subtitle="Houston Region"
        mapStyles={mapStyles}
      />
    </MapProvider>
  </MapboxProvider>
);

export default DistrictMap;
