import { CssBaseline, ThemeProvider } from '@material-ui/core';
import * as Sentry from '@sentry/browser';
import { inject as vercelInject } from '@vercel/analytics';
import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import { ErrorBoundary } from './components/ErrorBoundary';
import theme from './theme';

const { REACT_APP_SENTRY_DSN } = process.env;

Sentry.init({
  dsn: REACT_APP_SENTRY_DSN || '',
  blacklistUrls: [/https?:\/\/localhost/],
  environment: process.env.NODE_ENV
});

vercelInject();

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <App />
      </ThemeProvider>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root')
);
