import { Box, Button, Typography } from '@material-ui/core';
import * as Sentry from '@sentry/browser';
import * as React from 'react';

/* eslint-disable react/destructuring-assignment */
export interface State {
  error: Error | null;
  eventId: string | undefined;
}

export class ErrorBoundary extends React.Component<unknown, State> {
  constructor(props: unknown) {
    super(props);
    this.state = { error: null, eventId: undefined };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    this.setState({ error });
    Sentry.withScope((scope) => {
      scope.setExtras({ errorInfo });
      const eventId = Sentry.captureException(error);
      this.setState({ eventId });
    });
  }

  openCrashReport = (): void => {
    Sentry.showReportDialog({
      eventId: this.state.eventId,
      subtitle: '',
      subtitle2: '',
      title: 'Submit a Crash Report'
    });
  };

  render(): JSX.Element | React.ReactNode {
    if (this.state.error) {
      return (
        <Box mt={8} display="flex" flexDirection="column" alignItems="center">
          <Typography>It looks like there was an issue</Typography>
          <Typography>
            Please try reloading the page to resolve the problem
          </Typography>
          <Typography>
            If you would like to help us better resolve the issue, you can also
          </Typography>
          <Button color="primary" onClick={this.openCrashReport}>
            Submit a Crash Report
          </Button>
        </Box>
      );
    }

    return this.props.children;
  }
}
