import { Header, Layout1, Sidebar, StackedTitle } from '@ljagis/react-core';
import {
  GeolocationButton,
  Identify,
  Layers,
  LocationSearch,
  LocationSearchResult,
  Map,
  MapOverlay,
  MeasureResults,
  StyleGallery,
  ZoomInButton,
  ZoomOutButton,
  mapboxGeocode,
  useMapIdentify,
  useMapViewport,
  useMeasure
} from '@ljagis/react-mapping';
import {
  Box,
  Button,
  Divider,
  IconButton,
  Theme,
  Toolbar,
  Tooltip,
  Typography,
  makeStyles
} from '@material-ui/core';
import IdentifyIcon from '@material-ui/icons/FeaturedPlayListOutlined';
import LayersIcon from '@material-ui/icons/LayersOutlined';
import MeasureIcon from '@material-ui/icons/StraightenOutlined';
import React, { useEffect, useState } from 'react';

import { DistrictConfig } from '../models/DistrictConfig';

const { REACT_APP_MAPBOX_ACCESS_TOKEN } = process.env;

type sidebars = 'layers' | 'identify' | 'measure';

export const searchLocation = (
  mapboxAccessToken: string,
  proximity: [number, number] | undefined
) => {
  return (query: string): Promise<LocationSearchResult[]> =>
    mapboxGeocode(query, mapboxAccessToken, {
      proximity
    });
};

type LayoutProps = DistrictConfig;

const useStyles = makeStyles((theme: Theme) => ({
  disabledHeaderButton: {
    opacity: 0.6,
    color: `${theme.palette.primary.main} !important`
  }
}));

const Layout: React.FC<LayoutProps> = ({
  logo,
  title,
  subtitle,
  mapStyles
}) => {
  const classes = useStyles();

  const {
    layerNames: identifyLayers,
    features: identifyFeatures,
    location: identifyLocation,
    clearFeatures: clearIdentify,
    activate: activateIdentify,
    deactivate: deactivateIdentify
  } = useMapIdentify();

  const {
    length: measureLength,
    area: measureArea,
    lastPoint: measureLastPoint,
    isFinished: isMeasureFinished,
    activate: activateMeasure,
    deactivate: deactivateMeasure,
    reset: resetMeasure
  } = useMeasure({
    isActive: false
  });

  const { center } = useMapViewport();

  const [sidebar, setSidebar] = useState<sidebars | undefined>('layers');

  const sidebars: Record<
    sidebars,
    { title: string; content: React.ReactNode }
  > = {
    layers: {
      title: 'Layers',
      content: (
        <Box flex={1} display="flex" flexDirection="column" minHeight={0}>
          <Layers flex={1} />
          <Divider />
          <StyleGallery items={mapStyles} />
        </Box>
      )
    },
    identify: {
      title: 'Feature Details',
      content: (
        <Identify
          flex={1}
          layerNames={identifyLayers}
          features={identifyFeatures}
          location={identifyLocation}
          onClearFeatures={clearIdentify}
        />
      )
    },
    measure: {
      title: 'Measure',
      content: (
        <Box flex={1} display="flex" flexDirection="column" overflow="hidden">
          {measureLastPoint !== null && (
            <React.Fragment>
              <Box py={2}>
                <Toolbar disableGutters style={{ minHeight: 0 }}>
                  <Box mx={2}>
                    {!isMeasureFinished && (
                      <Typography variant="body2" color="textSecondary">
                        Double click to complete
                      </Typography>
                    )}
                  </Box>
                  <Box flex={1} />
                  <Box mr={1}>
                    <Button
                      color="primary"
                      style={{
                        marginTop: -6,
                        marginBottom: -6
                      }}
                      onClick={resetMeasure}
                    >
                      Start Over
                    </Button>
                  </Box>
                </Toolbar>
              </Box>
              <Divider />
            </React.Fragment>
          )}
          <Box p={2} flex={1} overflow="hidden auto">
            <MeasureResults
              area={measureArea}
              length={measureLength}
              lastPoint={measureLastPoint}
            />
          </Box>
        </Box>
      )
    }
  };

  // Open identify sidebar on any new identified features
  useEffect(() => {
    if (!identifyFeatures.length) {
      return;
    }
    setSidebar('identify');
  }, [identifyFeatures]);

  // Activate/deactivate identify vs measure when measure sidebar opens
  useEffect(() => {
    if (sidebar !== 'measure') return undefined;
    deactivateIdentify();
    activateMeasure();

    return () => {
      activateIdentify();
      deactivateMeasure();
    };
  }, [
    sidebar,
    activateIdentify,
    deactivateIdentify,
    activateMeasure,
    deactivateMeasure
  ]);

  return (
    <Layout1
      height="100vh"
      header={
        <Header minHeight={56} color="secondary">
          <Box
            mr={3}
            py={1}
            height={1}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <img src={logo} alt="Logo" height={48} width={98} />
          </Box>
          <Box flex="1 1 auto">
            {title !== undefined && (
              <StackedTitle title={title} subtitle={subtitle} />
            )}
          </Box>
          <Tooltip title="Layers">
            <span>
              <IconButton
                color="inherit"
                disabled={sidebar === 'layers'}
                classes={{
                  disabled: classes.disabledHeaderButton
                }}
                onClick={() => setSidebar('layers')}
              >
                <LayersIcon />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title="Measure">
            <span>
              <IconButton
                color="inherit"
                disabled={sidebar === 'measure'}
                classes={{
                  disabled: classes.disabledHeaderButton
                }}
                onClick={() => setSidebar('measure')}
              >
                <MeasureIcon />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title="Feature Details">
            <span>
              <IconButton
                color="inherit"
                disabled={sidebar === 'identify'}
                edge="end"
                classes={{
                  disabled: classes.disabledHeaderButton
                }}
                onClick={() => setSidebar('identify')}
              >
                <IdentifyIcon />
              </IconButton>
            </span>
          </Tooltip>
        </Header>
      }
      map={
        <Map
          style={{ height: '100%' }}
          initialMapStyle={mapStyles[0]?.styleUrl}
        />
      }
      mapOverlayTopLeft={[
        <MapOverlay key="locationSearch">
          <Box key={1} minWidth={240}>
            <LocationSearch
              maxZoom={19}
              onSearch={searchLocation(
                REACT_APP_MAPBOX_ACCESS_TOKEN || '',
                center && [center.lng, center.lat]
              )}
            />
          </Box>
        </MapOverlay>
      ]}
      mapOverlayTopRight={[
        <MapOverlay key="navigation">
          <Box>
            <ZoomInButton />
            <Divider />
            <ZoomOutButton />
          </Box>
        </MapOverlay>,
        <MapOverlay key="geolocation">
          <GeolocationButton />
        </MapOverlay>
      ]}
      sidebar={
        sidebar && (
          <Sidebar
            height={1}
            title={sidebars[sidebar].title}
            onClose={() => setSidebar(undefined)}
            content={sidebars[sidebar].content}
          />
        )
      }
    />
  );
};

export default Layout;
